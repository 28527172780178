import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { Main } from 'layouts';
import Container from 'components/Container';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})

analytics.page()

const mock = [
  {
    title: 'Our Mission',
    subtitle:
      'Our mission is to provide a nurturing atmosphere where everyone is valued and respected. All staff members, in partnership with parents and families are fully committed to the students over all development. Students are empowered to meet current and future challenges to develop social awareness, civic responsibility and enabling personal growth  ',
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
        />
      </svg>
    ),
  },
  {
    title: ' Our Motto',
    subtitle:
      'Reach Out, Reach High, Reach Beyond',
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
        />
      </svg>
    ),
  },
  {
    title: 'Our Vision',
    subtitle:
      'In a learner-centric, engaging and effective environment, integrated with methods assimilating blended learning, inclusive of technology and updated instructional methods, develop inquisitive learners with integrity of character driven by excellence, to actualize their potential in pursuit of being Life - Long Learners and Global Citizens.',
    icon: (
      <svg
        height={24}
        width={24}
        
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
        />
      </svg>
    ),
  },
];

const VissionAndMission = () => {
  const theme = useTheme();
  return (
    <Main>
        <Container  maxWidth={0.75}  paddingTop={'0 !important'} alignItems={'center'}>
            <Grid alignContent={'center'}>
                <Typography align='center' variant='h4' fontWeight={700}>
                <br/><br/>VISION & MISSION
                </Typography>
                
                <br/>
                <Typography align='center' component={'p'}>
                   Our aim is to be one of the leading schools in the community it serves, known and widely acknowledged as a secular institution which develops character and ethical leadership in its students in their pursuit of excellence.<br/><br/>
                 <br/>
                </Typography>
            </Grid>
            </Container>
            <Container  maxWidth={0.8}  paddingTop={'0 !important'} alignItems={'center'}>
    <Box >
      <Grid container spacing={2} alignContent='center'>
        {mock.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box
              width={1}
              height={1}
              data-aos={'fade-up'}
              data-aos-delay={i * 100}
              data-aos-offset={100}
              data-aos-duration={600}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Box
                  component={Avatar}
                  width={60}
                  height={60}
                  marginBottom={2}
                  bgcolor={alpha(theme.palette.primary.main, 0.1)}
                  color={theme.palette.primary.main}
                >
                  {item.icon}
                </Box>
                <Typography
                  variant={'h6'}
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                  align={'center'}
                >
                  {item.title}
                </Typography>
                <Typography align={'justify'} color="text.secondary">
                  {item.subtitle}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
    </Container>
    </Main>
  );
};

export default VissionAndMission;